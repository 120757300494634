import React from "react";

const HomeBanner = () => {

    return (
        <section className="home-banner mb-2rem">
            <div className="inner-content d-flex justify-content-center align-items-center">
                <div className="content text-center">
                    <h1>Maintenance Services 610 </h1>
                    <a href="#contactUs"><button className="btn btn-primary mt-3">Get In Touch</button></a>
                </div>
            </div>
        </section>
    )
}

export default HomeBanner;