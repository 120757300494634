import React from "react";
import ContactForm from "./ContactForm";


const Contact = () => {

    return (
        <section id="contactForm">
        <div className="discoverpianosection">
            <div className="container px-3">
                <div className="bottom-content mt-5 mb-5">
                    <hr />
                    <div className="header-content w-75 mx-auto text-center mt-5">
                        <h2>Reach out and see how we can help you today</h2>
                        <p className="mt-3">Please include a few details about the issue we can help you with so that we can route it to the appropriate department.</p>
                    </div>
                </div>
                
                {/* Contact Form Starts */}
                <div className="getin-touch my-5">
                    <ContactForm />
                </div>
                {/* Contact Form Ends */}

            </div>
        </div>
        </section>
    )
}

export default Contact;

