import React, { useRef } from "react";
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate, useLocation } from 'react-router-dom';

const recaptchaRef = React.createRef();

function onChange(value) {
    console.log("Captcha: ", value);
}

export const ContactUs = () => {
  var isSuccessPage = false;

  var location = useLocation();
  if(location.pathname === '/success'){isSuccessPage = true }

  const form = useRef();
  const Navigate = useNavigate();

  const sendEmail = (e) => {
    e.preventDefault();
    
    emailjs
      .sendForm('contact_from_Quinns_Pian', 'template_form_submission', form.current, { publicKey: "qBxH2cGP0FVJLErMU"})
      .then(
        () => {
          
          console.log('Form was submitted successfully!');
          window.scrollTo(0, 0);
          return Navigate("/success");
          
        },
        (error) => {
          console.log('FAILED...', error.text);
          return null;
        },
      );
  };

  return (
    <section id="contactUs">
    <div className="shadow-lg contact-from w-50 mx-auto p-4">
        <div className="heading text-center">
            {isSuccessPage
              ? <h3>Thank you for requestion more information</h3>
              : <h3>Request More Information</h3>
            }
            <hr/>
        </div>
        <div className="form-body mt-3">
            <form ref={form} onSubmit={sendEmail}>
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <label>Name</label>
                        <input type="text" name="from_name" id="from_name" className="form-control mt-2" placeholder="Enter Your Name" />
                    </div>
                    <div className="col-md-12 mb-3">
                        <label>Phone Number</label>
                        <input type="text" name="from_phone" className="form-control mt-2" placeholder="Enter Your Phone" />
                    </div>
                    <div className="col-md-12 mb-3">
                        <label>Email Address</label>
                        <input type="text" name="from_email" className="form-control mt-2" placeholder="Enter Your Email" />
                    </div>
                    <div className="col-md-12 mb-3">
                        <label>Message</label>
                        <textarea rows="6" name="from_message" className="form-control mt-2" placeholder="Type Message..." >
                        </textarea>
                    </div>
                    <ReCAPTCHA sitekey="6LeRlaIpAAAAAITVYHg4_HvPEeHPQf6WOqt4T-sV" ref={recaptchaRef} onChange={onChange}/>
                    <div className="col-md-12 mt-2">
                        {isSuccessPage
                          ? <button id="formButton" type="submit" className="btn btn-success w-100">Thank you!</button>
                          : <button id="formButton" type="submit" className="btn btn-primary w-100">Request More Information</button>
                        }
                        
                    </div>
                </div>
            </form>
        </div>
    </div>
    </section>
  );
};


export default ContactUs;
