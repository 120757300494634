export default function AutoRepair() {
    return (
      <section id="Intro">
      <div className="discoverpianosection">
          <div className="container px-3">
              <div className="header-content w-75 mx-auto text-center ">
              <div className="detailContainer">
                <h2>We can keep your fleet!</h2>
              </div>
              <div className="detailContainer">
                <img className="detailImage" alt="" src="../assets/images/callout5.png"></img>
                <h5>We also offer comprehensive fleet services, including same-day roadside assistance, ensuring your fleet stays operational and getting your vehicles back on the road in as little as 30 minutes.</h5>
                </div>
                      
                <div className="detailContainer ">
                  <p className="detailText"><b>Prompt Roadside Assistance: </b> Our roadside assistance service is designed to provide swift support whenever and wherever you need it. Whether you're stranded with a flat tire, dead battery, or any other roadside issue, our team is just a call away. With our same-day response guarantee, we'll have a technician dispatched to your location promptly, equipped to resolve the issue efficiently. From jump-starts to tire changes, lockouts to fuel delivery, we're here to get you back on the road safely in no time.</p>
                  <img className="detailImage" alt="" src="../assets/images/callout12.png"></img>
                </div>
  
                <div className="detailContainer">
                <img className="detailImage" alt="" src="../assets/images/callout7.png"></img>
                  <p className="detailText"><b>Same-Day Diagnostics and Repair: </b>When your vehicle encounters mechanical or electrical issues, time is of the essence. That's why we offer same-day diagnostics and repair services to minimize downtime and ensure your vehicle is back in operation quickly. With our team of skilled technicians and state-of-the-art diagnostic equipment, we'll swiftly identify the root cause of the problem and implement the necessary repairs on-site or at our facility. Whether it's a check engine light, brake malfunction, or electrical issue, you can rely on us to provide efficient solutions tailored to your needs.</p>
                </div>
  
                <div className="detailContainer">
                  <p className="detailText"><b>Effortless Dispatch Process: </b>At our service center, we understand the importance of convenience and simplicity, especially when you're facing vehicle issues. That's why we've streamlined our dispatch process to make it as effortless as possible for our customers. With just one phone call, you can dispatch our team to your location, whether it's for roadside assistance or on-site diagnostics and repair. Our friendly and knowledgeable staff will gather all the necessary details and ensure that help is on the way promptly, allowing you to focus on other priorities while we take care of your vehicle.</p>
                  <img className="detailImage" alt="" src="../assets/images/callout13.png"></img>
                </div>
  
                <div className="detailContainer">
                  <img className="detailImage" alt="" src="../assets/images/callout16.png"></img>
                  <p className="detailText "><b>Cost-Effective Fleet Maintenance: </b>Maintaining a dedicated team of mechanics for your fleet can be costly and inefficient. With our fleet services, you can save money and streamline your operations by outsourcing your maintenance needs to us. Instead of dealing with the overhead costs associated with hiring and managing in-house mechanics, you can rely on our expertise and resources to keep your fleet in top condition. From regular maintenance tasks to emergency repairs, we'll handle it all, allowing you to focus on running your business while we take care of your vehicles' needs.</p>
                </div>      
              </div>
          </div>
      </div>
      </section>
     );
   }
   
   
   