export default function AutoRepair() {
  return (
    <section id="Intro">
    <div className="discoverpianosection">
        <div className="container px-3">
            <div className="header-content w-75 mx-auto text-center ">
              <div className="detailContainer">
                <h2>What car  problems can we fix?</h2>
              </div>
              <div className="detailContainer">
                <img className="detailImage" alt="" src="../assets/images/callout2.png"></img>
                <h5>Our highly trained professionals accurately diagnose and can throughly explain all your car's electrical issues and warning and maintenance lights including Check Engine, TPMS, LKA, LDW and many more.</h5>
              </div>      
              <div className="detailContainer ">
                <p className="detailText"><b>Comprehensive Electrical Solutions </b>
  At our auto service center, we specialize in resolving a wide range of electrical issues that vehicles encounter. From diagnosing and addressing the notorious check engine light to tackling advanced systems like Advanced Driver Assistance Systems (ADAS), Tire Pressure Monitoring Systems (TPMS), Lane Keeping Assist (LKA), Lane Departure Warning (LDW), and Eyesight technology, our team is well-equipped to handle it all. Whether it's deciphering complex warning lights or dealing with common problems such as cars not starting or emitting unusual noises, we provide thorough diagnostics and effective solutions to get your vehicle back on the road safely.</p>
                <img className="detailImage" alt="" src="../assets/images/scanner.png"></img>
              </div>

              <div className="detailContainer">
              <img className="detailImage" alt="" src="../assets/images/diagnostics.png"></img>
                <p className="detailText"><b>Expert Diagnosis and Repair </b>
With years of experience and advanced diagnostic tools at our disposal, our technicians excel at accurately pinpointing electrical issues in vehicles of all makes and models. When your check engine light or any other warning indicator illuminates, it can be a cause for concern. Our experts are adept at interpreting these signals and swiftly identifying the underlying problems. Whether it's recalibrating ADAS features, troubleshooting TPMS sensors, or resolving issues with vehicle starting systems, we deliver precise diagnosis and efficient repairs to restore your vehicle's functionality.</p>
              </div>

              <div className="detailContainer">
                <p className="detailText"><b>Specialized Attention to Advanced Systems </b>
As vehicles become more technologically advanced, so do their electrical systems. ADAS, TPMS, LKA, LDW, and Eyesight are just a few examples of sophisticated systems designed to enhance safety and performance on the road. However, when these systems malfunction, it requires specialized attention from knowledgeable professionals. Our team undergoes continuous training to stay abreast of the latest advancements in automotive technology, ensuring that we can effectively diagnose and repair these complex electrical components to manufacturer standards.</p>
                <img className="detailImage" alt="" src="../assets/images/adas2.png"></img>
              </div>

              <div className="detailContainer">
                <img className="detailImage" alt="" src="../assets/images/callout3.png"></img>
                <p className="detailText"><b>Addressing Common Electrical Challenges </b>
In addition to handling advanced systems, we understand the frustration that common electrical issues can cause for vehicle owners. Whether it's a dead battery, faulty alternator, malfunctioning starter, or mysterious noises emanating from your car, our team is here to help. We perform thorough inspections and testing to identify the root cause of these issues, providing efficient and cost-effective solutions to get you back on the road with confidence.</p>
              </div>

              <div className="detailContainer">
                <p className="detailText "><b>Customer-Centric Approach </b>
At our auto service center, customer satisfaction is paramount. We prioritize clear communication and transparency throughout the diagnostic and repair process, ensuring that you understand the nature of the electrical issues affecting your vehicle and the proposed solutions. With our dedication to quality craftsmanship and personalized service, you can trust us to deliver exceptional results and keep your vehicle running smoothly for miles to come.</p>
<img className="detailImage" alt="" src="../assets/images/callout9.png" ></img>
              </div>      
            </div>
        </div>
    </div>
    </section>
   );
 }
 
 
 