import React from "react";
import { Link } from "react-router-dom";


const Header = () => {

  return (
    <header className="ds-flex-center-center">
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container">
          <a className="navbar-brand" href="/">
          <img alt="" src="../assets/images/logo.png" height='120' className="logo" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="/">Home</a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/AutoRepair" >Automotive Repair</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/FleetMaintenance">Fleet Maintenance</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/RepairInsurance">Auto Repair Insurance</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/Locksmith">General Maintenance Services</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/ContactUs">Contact Us</Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="tel:6103653313">Call now!</a>
                </li>
              </ul>


          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header;