import React from "react";

const RightToLeftCard = ({heading, info, img}) => {

    return (
        <div className="row mx-2 mb-3">
            <div className="col-md-6 d-flex align-items-center ps-0">
                <div className="right-content text-start ps-0 ">
                    <h4>{heading}</h4>
                    <p className="mt-2 justify">{info}</p>
                </div>
            </div>
            <div className="col-md-6 text-end">
                <img src={img} className="rounded-circle" width='340' height='340' alt="" />
            </div>
        </div>
    )
}

export default RightToLeftCard;