import React from "react";
const currentYear = new Date().getFullYear();

const Footer = () => {

    return (
        <footer className="bg-primary text-center pt-2 pb-1 text-light ">
            <p>© {currentYear} All Rights Reserved. Maintenance Services 610</p>
        </footer>
    )
}

export default Footer