import React from "react";
import RightToLeftCard from "./RightToLeftCard";
import LeftToRightCard from "./LeftToRightCard";

const Services = () => {

    return (
        <section id="services">
        <div className="discoverpianosection">
            <div className="container px-3">
                <div className="content">
                    <div className="header-content w-75 mx-auto text-center">
                        <h2>Automotive Services Provided</h2>
                    </div>
                    <div className="body-content mt-5">
                        <RightToLeftCard
                            heading='On-Site, Same Day Vehicle Diagnostics'
                            img="./assets/images/diagnostics.png"
                            info="At Maintenance Services 610, we understand the frustration of unexpected vehicle issues and the urgency for swift resolutions. That's why we offer onsite same-day vehicle diagnostics for both automotive garages and end-users throughout the Lehigh Valley. When garages encounter perplexing problems that leave them scratching their heads, our expert technicians are just a call away. We swiftly deploy to their location, equipped with advanced diagnostic tools and years of industry experience, to pinpoint the root cause and get them back on track. Additionally, for end-users who prefer the convenience of having their vehicles assessed at home, our fast and efficient service ensures minimal disruption to their day. Whether you're a garage in need of reliable support or a car owner seeking convenience, Maintenance Services 610 is your trusted partner for prompt and professional onsite diagnostics."
                        />
                        <LeftToRightCard
                            heading="Module Programming"
                            img="./assets/images/modules.png"
                            info="At Maintenance Services 610, we go above and beyond to ensure your vehicle's electronic control modules are programmed correctly the firs time. Whether it's a PCM, TCM, or ECM replacement, our state-of-the-art equipment and expert programmers are ready to handle the task, catering to any vehicle, including BMW and Mercedes models. Before making any module purchase, give us a call, and our team will provide you with multiple solutions tailored to your needs. Not only do we offer programming services, but we also specialize in repairing computer modules, eliminating the need for additional programming. Plus, our thorough testing process ensures that you're not spending money on modules you can't return. Trust Maintenance Services 610 for reliable, cost-effective solutions to all your module needs."
                        />
                        <RightToLeftCard
                            heading='Advanced Driver Assist System | ADAS'
                            img="./assets/images/adas2.png"
                            info="In the rapidly evolving landscape of automotive technology, Advanced Driver Assistance Systems (ADAS) have become the new standard for vehicles. At Maintenance Services 610, our team of expert technicians are at the forefront of this revolution, equipped with extensive training and cutting-edge equipment to calibrate and program ADAS features for every make and model to factory specifications. With thousands of dollars worth of specialized equipment, we bring the dealership experience directly to you. By offering on-site programming services, we eliminate the need for you to wait at a dealership, saving you time and ultimately boosting your bottom line. Trust Maintenance Services 610 to keep your vehicle's ADAS functioning optimally, ensuring safety and performance on the road."
                        />
                        <LeftToRightCard
                            heading="Fleet Maintenance"
                            img="./assets/images/fleet.png"
                            info="At Maintenance Services 610, we understand the critical importance of keeping your fleet operational and minimizing downtime for your business. That's why we offer comprehensive Fleet Maintenance solutions tailored to your needs. With our On-Site, Same Day service, all it takes is a simple text message, and our skilled technicians are dispatched directly to your job site or garage. Our efficient team works swiftly, often having your vehicles back up and running in as little as 30 minutes, ensuring minimal disruption to your operations. Our Fleet Maintenance package includes preventive maintenance measures aimed at keeping your vehicles in top condition and preventing breakdowns. We go the extra mile by servicing your fleet on weekends, ensuring that your vehicles are ready to hit the road when you need them most during the week. Trust Maintenance Services 610 to keep your fleet running smoothly and your business moving forward."
                        />
                        <RightToLeftCard
                            heading='Automotive Remote Starters'
                            img="./assets/images/remoteStarter.png"
                            info="
                            At Maintenance Services 610, we're proud to offer top-of-the-line remote starters that deliver unparalleled convenience. With the ability to turn your vehicle on from over a mile away, our remote starters bring comfort and ease to your driving experience. We specialize in installing and maintaining all major brands, including Viper, Compustar, and Idatalink remote starters and security systems, ensuring you have access to the latest technology. Unlike other shops, we understand the importance of keeping your spare keys in hand. With our expertise, we ensure that you can keep your keys with you, eliminating the hassle of surrendering a second key. Trust Maintenance Services 610 for expert installation and maintenance of remote starters, providing you with unmatched convenience and peace of mind on the road."
                        />
                    </div>
                    <div className="header-content w-75 mx-auto text-center">
                        <h2>Locksmith & Other Maintenance Services Provided</h2>
                    </div>
                    <div className="body-content mt-5">
                        <RightToLeftCard
                            heading='Access Control Systems'
                            img="./assets/images/accessControl.png"
                            info="
                            At Maintenance Services 610, we specialize in the installation and repair of access control systems, including video doorbells and key card systems. Our team of highly trained technicians delivers expert repairs to address any issues promptly. Whether it's a malfunction or routine maintenance, our technicians ensure your access control systems operate smoothly. With our comprehensive services, you can trust us to maintain the security of your property effectively."
                        />
                        <LeftToRightCard
                            heading="Automotive Locksmith"
                            img="./assets/images/autoLocksmith.png"
                            info="Smart keys have become the standard for modern vehicles, and our expert technicians are equipped with dealer-grade tools to safely program new keys for all vehicle makes and models. Additionally, we offer comprehensive maintenance and repair services for vehicle security systems. Today's car modules are intricately linked through complex security protocols, making repairs a challenging task. Our team specializes in providing the necessary assistance to ensure your modules are synced and programmed correctly, all done conveniently on-site for your peace of mind and convenience. Trust us to handle all your smart key programming and vehicle security system needs with precision and expertise."
                        />
                        <RightToLeftCard
                            heading='Commercial & Residential Locksmith'
                            img="./assets/images/commercialLocksmith.png"
                            info="As lock technology advances, complexities increase, especially with the integration of keypads and Bluetooth. Our technicians excel at installing and maintaining these sophisticated systems to enhance your safety. Should you encounter a lockout situation or experience a malfunction, count on us to swiftly resolve the issue and restore access to your property."
                        />
                        <LeftToRightCard
                            heading="General Contractor & Maintenance"
                            img="./assets/images/handyman.png"
                            info="As a trusted general contractor affiliated with Home Depot, our company is dedicated to fulfilling all your home improvement needs with excellence and efficiency. With a commitment to quality service, we offer a comprehensive range of solutions, ensuring that every aspect of your home receives the attention it deserves. From renovations to repairs, our skilled team handles it all, delivering results that exceed expectations. Additionally, we provide furniture assembly, playset assembly, appliance maintenance, repair and provide general handyman services promptly and affordably. Whether it's a quick fix or a slightly bigger project, you can trust us to get the job done swiftly and with the utmost care, making your home a better place to live, one task at a time."
                        />
                    </div>
                </div>
            </div>
        </div>
        </section>
    )
}

export default Services;

