export default function AutoRepair() {
    return (
      <section id="Intro">
      <div className="discoverpianosection">
          <div className="container px-3">
              <div className="header-content w-75 mx-auto text-center ">
              <div className="detailContainer">
                <h2>Do you have car repair insurance like Carshield or Geico Repair?</h2>
              </div>
              <div className="detailContainer">
                <h5>We work with automotive repair insurance companies like Geico, Progressive, CarShield, and more, we handle the entire claims process seamlessly, ensuring fast turnaround times with same-day diagnostics and repairs, so you can get back on the road without worry. </h5>
                <img className="detailImage" alt="" src="../assets/images/callout6.png"></img>
                </div>
                      
                <div className="detailContainer ">
                  <p className="detailText"><b>Streamlined Insurance Claim Handling: </b>When it comes to automotive repair insurance, we understand the importance of a hassle-free experience for our customers. That's why, when you choose us, you can rest assured that we'll handle the entire insurance claim process on your behalf. Whether you're working with Geico, Progressive, CarShield, American Auto Repair Network, or any other insurance provider, we have the expertise and experience to navigate the complexities of the claims process efficiently. With our dedicated team managing the claim details, you can forget about the paperwork and administrative headaches and focus on getting back to your daily routine.</p>
                  <img className="detailImage" alt="" src="../assets/images/callout17.png"></img>
                </div>
  
                <div className="detailContainer">
                <img className="detailImage" alt="" src="../assets/images/callout1.png"></img>
                  <p className="detailText"><b>Efficient Repairs for Minimal Downtime: </b>Our goal is to get you back on the road quickly and with minimal disruption to your life. Unlike traditional repair shops where long wait times are common due to backlogs, we prioritize efficiency and same-day service. From prompt diagnostics to swift repairs, our technicians work diligently to ensure that your vehicle is back in your hands as soon as possible. With our streamlined processes and commitment to fast turnaround times, you won't have to wait weeks without a car because the shop is full. We understand the importance of reliable transportation, and we're dedicated to providing timely solutions that keep you moving forward.</p>
                </div>
  
                <div className="detailContainer">
                  <p className="detailText"><b>Same-Day Diagnostics and Repairs: </b>At our auto repair service, we pride ourselves on our ability to provide same-day diagnostics and repairs. When you bring your vehicle to us, you can expect prompt attention and swift action to identify and address any issues it may have. Our skilled technicians leverage advanced diagnostic tools and years of experience to pinpoint the root cause of the problem efficiently. Whether it's a minor repair or a more complex issue, we have the resources and expertise to get the job done right the first time. With our commitment to same-day service, you can trust us to get you back on the road quickly and with confidence.</p>
                  <img className="detailImage" alt="" src="../assets/images/callout10.png"></img>
                </div>
              </div>
          </div>
      </div>
      </section>
     );
   }
   
   
   