export default function ErrorPage() {
 return (
    <div id="error-page" className="w-75 mx-auto px-4 text-center">
      <h1>Oops looks like something unexpected happened!</h1>
      <p>Sorry!</p>
      <h2> <a href="/">Go Back Home</a></h2>
    </div>
  );
}


