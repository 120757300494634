export default function AutoRepair() {
    return (
      <section id="Intro">
      <div className="discoverpianosection">
          <div className="container px-3">
              <div className="header-content w-75 mx-auto text-center ">
              <div className="detailContainer">
                <h2>General Contracting & Maintenance Solutions for your home, building or auto.</h2>
              </div>
              <div className="detailContainer">
                <img className="detailImage" alt="" src="../assets/images/handyman.png"></img>
                <h5>As a trusted locksmith, we provide comprehensive services, from key replacement to programming and remote starter installation, ensuring reliable access to your vehicle with efficiency and expertise.</h5>
                </div>
                      
                <div className="detailContainer ">
                  <p className="detailText"><b>Comprehensive Key Replacement Services: </b>At our locksmith service, we specialize in key replacement, catering to all your needs, including smart keys and fobs. Whether you've lost your keys, need a spare set, or require key cutting for a new lock, our team has you covered. With precision key cutting equipment and expertise in handling various key types and models, we ensure that you have reliable access to your vehicle at all times.</p>
                  <img className="detailImage" alt="" src="../assets/images/callout8.png"></img>
                </div>
  
                <div className="detailContainer">
                <img className="detailImage" alt="" src="../assets/images/autoLocksmith.png"></img>
                  <p className="detailText"><b>Lost Key Replacement Expertise: </b>Losing your car keys can be a frustrating experience, but we're here to alleviate the stress. Our lost key replacement services are available for all makes and models of cars, providing solutions to get you back on the road quickly. Regardless of the circumstances, whether you've misplaced your keys or they've been stolen, our skilled technicians have the tools and knowledge to make a new key from scratch, ensuring seamless access to your vehicle.</p>
                </div>
  
                <div className="detailContainer">
                  <p className="detailText"><b>Efficient Key Programming Solutions: </b>In today's automotive landscape, key programming is essential for ensuring that your car's security system recognizes your keys. We offer comprehensive key programming services, including smart keys, to synchronize your new keys with your vehicle's immobilizer system. With our expertise and specialized equipment, we ensure that your keys are programmed correctly the first time, providing you with peace of mind and hassle-free access to your vehicle.</p>
                  <img className="detailImage" alt="" src="../assets/images/callout18.png"></img>
                </div>
  
                <div className="detailContainer">
                  <img className="detailImage" alt="" src="../assets/images/callout11.png"></img>
                  <p className="detailText"><b>Convenient Mobile Services: </b>One of the key advantages of choosing our locksmith services is our mobile capabilities. We understand the inconvenience of being stranded without access to your vehicle, which is why we bring our services directly to you. By opting for our mobile locksmith services, you not only save money on tow truck fees but also benefit from same-day service, getting you back on the road quickly and efficiently.</p>
                </div>
  
                <div className="detailContainer">
                  <p className="detailText "><b>Remote Starter Installation for Year-Round Comfort: </b>In addition to our key replacement and programming services, we also offer remote starter installation to enhance your driving experience. With a remote starter, you can keep your engine warm in winter and your car cool in summer, ensuring optimal comfort before you even step inside. With our reliable remote starter systems, you can enjoy the convenience and comfort of a pre-warmed or cooled vehicle at the touch of a button, making every journey more enjoyable.</p>
                  <img className="detailImage" alt="" src="../assets/images/remotestarter.png"></img>
                </div>      
              </div>
          </div>
      </div>
      </section>
     );
   }
   
   
   