import HomeBanner from './HomeBanner';
import Intro from './Intro';
import Services from './Services';

const Home = () => {

  return (
    <div>
    <HomeBanner/>
    <Intro />
    <Services />
    </div>
  )
}

export default Home;